import { convert } from 'html-to-text';

import Inquiry from '../type/Inquiry';
import naverKinAPI from './naverKinAPI';

export default async function crawlInquiry({
  urlList, naverCookie,
}: {
  urlList: string[]; naverCookie: string;
}): Promise<Inquiry[]> {
  const { data: htmls } = await naverKinAPI.post('/crawl', {
    urlList,
    naverCookie,
  });

  const inquiries = htmls.map((html: string, index: number) => {
    const text = convert(html, {
      baseElements: {
        selectors: [
          'div.endTitleSection',
          'div.questionDetail',
        ],
      },
    });

    const [title, ...rest] = text.split('\n');
    const content = rest.join('\n');

    return new Inquiry(urlList[index], title, content);
  });

  return inquiries;
}
