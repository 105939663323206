import { useCallback, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import crawlInquiry from '../service/crawlInquiry';
import createOutput from '../service/createOutput';
import postConsultation from '../service/postConsultation';

import ConsultationCategory from '../type/ConsultationCategory';

import crawlOutputState from '../store/CrawlOutputState';

export default function useNaverCrawl() {
  const [crawlOutput, setCrawlOutput] = useRecoilState(crawlOutputState);
  const initializeCrawlOutput = useResetRecoilState(crawlOutputState);

  const [isLoading, setIsLoading] = useState(false);

  const naverCrawl = async (
    urlList: string[],
    naverCookie: string,
    category: ConsultationCategory,
  ) => {
    const inquiries = await crawlInquiry({ urlList, naverCookie });

    const advices = await postConsultation({ inquiries, category });

    const outputs = createOutput(inquiries, advices, category);

    return outputs;
  };

  const consultAllUrl = useCallback(async (
    urlList: string[],
    naverCookie: string,
    category: ConsultationCategory,
  ) => {
    setIsLoading(true);

    const output = await naverCrawl(urlList, naverCookie, category);

    setCrawlOutput(output);
    setIsLoading(false);
  }, [setCrawlOutput]);

  const copyNokeLink = useCallback(async (index: number) => {
    await navigator.clipboard.writeText(crawlOutput[index].nokeLink);
  }, [crawlOutput]);
  const copyFinalAdvice = useCallback(async (index: number) => {
    await navigator.clipboard.writeText(crawlOutput[index].finalAdvice);
  }, [crawlOutput]);

  return {
    consultAllUrl,
    naverCrawl,
    crawlOutput,
    isLoading,
    copyNokeLink,
    copyFinalAdvice,
    initializeCrawlOutput,
  };
}
