export default class Inquiry {
  url: string;

  title: string;

  content: string;

  constructor(url: string, title: string, content: string) {
    this.url = url;
    this.title = title;
    this.content = content;
  }

  public toString(): string {
    return `${this.title}\n\n${this.content}`;
  }
}
