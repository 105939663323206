import SyncLoader from 'react-spinners/SyncLoader';

import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function PageLoading() {
  return (
    <Container>
      <SyncLoader color="#092C86" size={25} />
    </Container>
  );
}
