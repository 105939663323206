import { useState } from 'react';
import ConsultationCategory from '../type/ConsultationCategory';

export default function useNaverKinCrawlInput() {
  const [urlList, setUrlList] = useState<string[]>(['']);
  const [naverCookie, setNaverCookie] = useState('');
  const [category, setCategory] = useState<ConsultationCategory | null>(null);
  const categories: ConsultationCategory[] = ['legal', 'medical', 'admission', 'insurance', 'mumul'];

  const setUrl = (index: number, url: string) => {
    setUrlList((prev) => {
      const newList = [...prev];
      newList[index] = url;
      return newList;
    });
  };
  const addUrl = () => {
    if (urlList.length < 10) {
      setUrlList((prev) => [...prev, '']);
    }
  };
  return {
    setUrlList,
    setUrl,
    addUrl,
    setNaverCookie,
    urlList,
    naverCookie,
    category,
    categories,
    setCategory,
  };
}
