import { atom } from 'recoil';

import CrawlOutput from '../type/CrawlOutput';

const crawlOutputState = atom<CrawlOutput[]>({
  key: 'crawlOutputState',
  default: [],
});

export default crawlOutputState;
