/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useState } from 'react';
import validateEmail from '../service/validateEmail';

const Container = styled.div`
  max-width: 1024px;
  margin: 3em auto;

  h1 {
    font-size: 2em;
    margin-bottom: 1em;
    line-height: 1.5;
    text-align: center;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  img {
    width: 50%;
  }
`;

const LogInForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  button {
    background-color: #4b52ff;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

export default function LoginPage() {
  const [email, setEmail] = useState<string>('');

  const navigate = useNavigate();

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setEmail(value);
  };

  const handleClick = async () => {
    try {
      await validateEmail(email);
      navigate(`/otp?email=${email}`);
    } catch (e: any) {
      alert(e.message);
    }
  };

  return (
    <Container>
      <h1>노크 지식인 작업 페이지</h1>
      <Body>
        <img src="https://noke.s3.ap-northeast-2.amazonaws.com/assets/track_noke_thumbnail.gif" alt="로그인 이미지" />
        <LogInForm>
          <Field>
            <input placeholder="이메일을 입력해주세요" type="text" onInput={handleInput} />
          </Field>
          <button type="button" onClick={handleClick}>
            로그인하기
          </button>
        </LogInForm>
      </Body>
    </Container>
  );
}
