import ConsultationCategory from '../type/ConsultationCategory';

const consultationCategoryDictionary: {
  [Key in ConsultationCategory]: {
    korean: string;
    serviceDescription: string;
    tags: string;
  }
} = {
  legal: {
    korean: '법률',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 변호사 선임이나 사건을 맡기실 필요 없습니다. 법률사무소가 아니며 광고도 아닙니다.

2. 100% 무료로 법률상담을 제공해드립니다.

3. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

4. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료법률상담 AI변호사 AI법률상담 민사법률상담 형사법률상담 형량예측 법령정보 판례찾기 법률상담',
  },
  medical: {
    korean: '의료',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 의료 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료의료상담 AI의사 AI의료상담 내과상담 의학정보 증상분석 건강관리 정신건강 정형외과 성형외과',
  },
  admission: {
    korean: '입시',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 입시상담을 제공해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료입시상담 AI입시컨설턴트 AI입시상담 대학입시상담 생기부관리 세특주제 대입전략 합격가능성분석 전형문의 성적향상',
  },
  insurance: {
    korean: '보험',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 보험 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료보험상담 AI보험설계사 AI보험상담 보험상품비교 맞춤형보험추천 보험리모델링 보험가입전략 보험료분석 보장내용문의 보험료절약방법',
  },
  mumul: {
    korean: '무물',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 무엇이든 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무엇이든물어보세요 AI질문답변 궁금증해결 무엇이든답변 만능해결사 AIQ&A 질문있어요 AI상담사 실시간무료',
  },
};

export default consultationCategoryDictionary;
