import { encode } from 'urlencode';
import Inquiry from '../type/Inquiry';
import ConsultationCategory from '../type/ConsultationCategory';
import consultationCategoryDictionary from '../data/consultationCategoryDictionary';

function formatAdvice(advice: string) {
  const paragraphs = advice.split('\n\n\n');

  const first = paragraphs[0];
  const second = paragraphs[1] || '';
  const third = paragraphs[2] || '';
  const truncatedAdvice = [first, second, third].filter(Boolean).join('\n\n');

  return truncatedAdvice;
}

export default function createOutput(
  inquiries: Inquiry[],
  advices: string[],
  category: ConsultationCategory,
) {
  const outputs = inquiries.map((inquiry, index) => {
    const formattedAdvice = formatAdvice(advices[index]);

    const kinLink = inquiry.url;

    const query = `utm_source=naver&utm_medium=organic&utm_campaign=지식인&naverInquiry=${encode(inquiry.toString())}`;
    // TODO: 법률일 때만이라도 네이버에 링크 복붙시 박스를 보여주기위해 분리함, 차후에 홈페이지 autowrite 기능이 사라지면 삭제해야함
    const urlByCategory = category === 'legal' ? 'https://noke.ai' : `https://noke.ai/consultations/${category}`;
    const nokeLink = `${urlByCategory}?${query}`;

    const { serviceDescription, tags } = consultationCategoryDictionary[category];

    const finalAdvice = [formattedAdvice, serviceDescription, tags].join('\n\n');

    return {
      kinLink, nokeLink, finalAdvice,
    };
  });

  return outputs;
}
