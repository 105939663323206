import consultationCategoryDictionary from '../data/consultationCategoryDictionary';
import ConsultationCategory from '../type/ConsultationCategory';
import Inquiry from '../type/Inquiry';
import naverKinAPI from './naverKinAPI';

export default async function postConsultation({ inquiries, category }
: {
  inquiries: Inquiry[];
  category: ConsultationCategory;
}): Promise<string[]> {
  const { data } = await naverKinAPI.post('/consultations', {
    inquiryContents: inquiries.map((inquiry) => inquiry.toString()),
    category: consultationCategoryDictionary[category].korean,
  });

  console.log({ data });

  return data;
}
